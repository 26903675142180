@import "../../styles/variables";

.defaultMessage {

  padding-bottom: 4rem;
  @include tablet-down {
    padding: 0 2rem 4rem 2rem;
  }

  a {
    display: inline-block;
    padding: 1rem;
    width: auto;
    background: $orange;
    color: $white;
    margin: 1rem 0;
  }

}