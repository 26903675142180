@import "../../styles/variables";

.button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;
  overflow: hidden;
}


.square {
  flex-shrink: 0;
  flex-grow: 0;
  .inner {
    width: 100%;
    height: 100%;
    transition: transform 100ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover .inner, &:focus .inner {
    transform: scale(1.2);
  }
}

.block {
  @include type-sm;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.label {
  padding: 1rem;
}