@import "../../styles/variables";

/*
* allowed html tags:
* h1-h6, p, iframe, div, img
* a, strong, u, i, em, mark, code, small, sub, sup, del, ins, ul, li,
*
* does not have to be super accurate. but will prevent unpredictable outcome on the page.
*
*/


.body{
  @include type-sm;
  user-select: text;

  h1,h2,h3,h4,h5,h6{
    @include type-lg;
  }
  p{
    line-height: 1.25;
  }

}