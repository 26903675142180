@import "../../../styles/variables";

.wrapper {
  width: 1em;
  height: 1em;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
}

.iconLike {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;

}

.liked {
  path {
    fill: $orange;
  }
}

.iconArrW{
  margin-right: 1rem;
}