@import "../../styles/variables";

.wrapper {
  padding-bottom: 2rem;
}

.header {
  display: flex;
  padding: 1rem;
  max-width: 1000px;
  user-select: text;
}

.block{
  padding: 0 2rem;
  margin-bottom: 2rem;
  max-width: 1000px;
}
.mapLink{
  a{
    color: $violet;
  }
  svg {
    display: inline;
    height: 1em;
    width: 1em;
    margin-right: .2em;
    vertical-align: middle;
    * {
      fill: currentColor;
    }
  }
}

.headerCol {
  padding: 1rem;
}

.headline {
  flex-grow: 1;

  h1 {
    @include type-display-lg;
  }
}

.back {
}

.back svg {
  width: 2.2rem;
  height: 2.2rem;
}

.day {
  h3 {
    margin-bottom: 1rem;
  }
}

.events {
  padding: 0 1rem;
}