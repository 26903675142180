@import "../../styles/variables";

.wrapper {
    display: block;
    width: 100%;
    max-width: 450px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    @include tablet-up{
        display: none;
    }
}

.wrapper.svg {
    display: block;
    width: 100%;
}