@import "../../styles/variables";


.locator {
  position: absolute;
  transform: translate(-50%,-50%);
}
.active.locator{
  transform: translate(-50%,-50%) scale(1.5) ;
}
.inner{
}
.active .inner{
  color: $violet;
}

.circle {
  background: $orange;
  color: $white;
  width: 2rem;
  height: 2rem;
  display: flex;
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-variant-numeric: tabular-nums;
  @include type-display-sm;
}
