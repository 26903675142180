@import "../../styles/variables";

.wrapper{
    background: $violet;
    display: flex;
    flex-wrap: wrap;
}

.col{

    @include type-sm;

    padding: 1rem;
    text-align: center;
    flex: 0 0 100%;

    @include tablet-up{
        flex: 0 0 50%;
    }
    @include maxi{
        flex: 0 0 25%;
    }

    h5{
        width: 100%;
    }
    img{
        display: inline-block;
        width: 100%;
        max-width: 100px;
    }

}

.imageWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
    }
}