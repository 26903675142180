@import "../../styles/variables";

.wrapper {
  display: flex;

}

.linkWrapper{
  width: 100%;
  min-width: 150px;
  @include tablet-up{
    border-left: 1px solid $white;
  }
}


.linkWrapper + .linkWrapper {
  border-left: 1px solid $white;
}
