@import "../../../styles/variables";

.container {
  min-height: 70vh;
}

.row{
  display: flex;
  flex-wrap: wrap;
  padding: 0 .15rem;
}

.col{
  padding: 0 .15rem;
  @include mobile {
    width: 50%;
  }
  @include tablet{
    width: 33.333%;
  }
  @include desktop{
    width: 25%;
  }
  @include maxi{
    width: 20%;
  }
}

.colLg{
  padding: 0 .15rem;
  @include mobile {
    width: 100%;
  }
  @include tablet{
    width: 50%;
  }
  @include desktop{
    width: 33.333%;
  }
  @include maxi{
    width: 25%;
  }
}