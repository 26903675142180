@import "../../styles/variables";

.container {
}


.header {
}

.item {
  margin-bottom: 1px;
}

