@import "../../styles/variables";

.content{
  display: flex;
  height: 100%;
}
.icon{
  margin-right: -1rem;
}
.typo{
  white-space: nowrap;
  padding: .65rem 1rem .5rem 1rem;
}

.tags{
  @include type-display-xs;
}
.title{
  @include type-sm;
}
