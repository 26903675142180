@import "../../styles/variables";

.container {
  position: relative;
  overflow: hidden;
  padding-top: 100%;
  transition: padding-top 200ms ease;
}
.narrow {
  padding-top: 33.333%;
}


.stage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 100%;
  transition: top 200ms ease;
}


.image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: $grey;
  user-select: none;
}
