@import "../../styles/variables";

.wrapper{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrapper svg{
  display: block;
  max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto;
}