@import "../../../styles/variables";

.container {
  @include desktop {
    position: relative;
    padding-left: 20%;
  }
}

.sidebar {
  display: none;
  @include desktop {
    display: block;
    width: 20%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.inner {
  @include desktop {
    height: auto;
    width: 100%;
  }
}

.content {
}

.spacedBody{
  @include maxi{
    padding-right: 25%;
  }
}