$width-sm: 576px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;
$width-xxl: 1600px;

@mixin mobile {
  @media (max-width: #{$width-md}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$width-md}) and (max-width: #{$width-lg}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$width-lg}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$width-md}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$width-lg}) {
    @content;
  }
}

@mixin maxi {
  @media (min-width: #{$width-xxl}) {
    @content;
  }
}
