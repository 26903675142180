@import "../../styles/variables.scss";

.lg {
  @include type-lg;
}

.sm {
  @include type-sm;
}

.displaySm {
  @include type-display-sm;
}

.displayLg {
  @include type-display-lg;
}