@import "../../styles/variables";

.container {
  display: block;
  padding-bottom: 2rem;
  color: $black;
  text-decoration: none;
}

.info {
  padding: .5rem;
}

.favBtnWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.title {
  @include type-lg;
}

.tags {
  @include type-display-xs;
  line-height: 1;
}

.event {
  @include type-display-xs;
  line-height: 1;
  border-top: 1px solid $black;
  padding-top: .25rem;
  margin-top: .25rem;
  white-space: nowrap;
  overflow: hidden;
}