@import "../../styles/variables";

.container {
}

.labelRow{
}
.spacer{
  height: 1.5rem;
  background: $violet;
  border-right: 1px solid $violet;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
}
.label {
  background: $white;
  border-right: 1px solid $violet;
  color: $violet;
  padding: 1rem;
  display: flex;
  a{
    text-decoration: none;
  }
}