@import "../../styles/variables";

.wrapper {
  @include tablet-down{
    display: none;
  }
  svg {
    display: inline;
    height: 1em;
    width: 1em;
    margin-right: .2em;
    vertical-align: middle;
    * {
      fill: currentColor;
    }
  }
}
