@import "../../styles/variables";


.wrapper{
    width: 4rem;
    height: 4rem;
    padding: 1rem;
}
.wrapper svg{
    width: 100%;
    height: 100%;
    display: block;
}
.wrapper svg * {
    vector-effect: non-scaling-stroke;
    stroke-width: 1.8px;
    stroke-linecap: round;
    shape-rendering: geometricPrecision;
}


.fillNone svg *{
    fill: none;
}
.fillViolet svg *{
    fill: $violet;
}
.fillOrange svg *{
    fill: $orange;
}
.fillWhite svg *{
    fill: $white;
}
.fillBlack svg *{
    fill: $black;
}

.strokeNone svg *{
    stroke: none;
}
.strokeViolet svg *{
    stroke: $violet;
}
.strokeOrange svg *{
    stroke: $orange;
}
.strokeWhite svg *{
    stroke: $white;
    stroke-width: 1.5px;
}
.strokeBlack svg *{
    stroke: $black;
}