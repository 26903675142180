/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

//@import "/node_modules/bootstrap/scss/functions";
//@import "/node_modules/bootstrap/scss/variables";
//@import "/node_modules/bootstrap/scss/mixins";

//@import "/node_modules/bootstrap/scss/root";
//@import "/node_modules/bootstrap/scss/reboot";

//@import "/node_modules/bootstrap/scss/type";
//@import "/node_modules/bootstrap/scss/images";
//@import "/node_modules/bootstrap/scss/code";
//@import "/node_modules/bootstrap/scss/grid";
//@import "/node_modules/bootstrap/scss/tables";
//@import "/node_modules/bootstrap/scss/forms";
//@import "/node_modules/bootstrap/scss/buttons";
//@import "/node_modules/bootstrap/scss/transitions";
//@import "/node_modules/bootstrap/scss/dropdown";
//@import "/node_modules/bootstrap/scss/button-group";
//@import "/node_modules/bootstrap/scss/input-group";
//@import "/node_modules/bootstrap/scss/custom-forms";
//@import "/node_modules/bootstrap/scss/nav";
//@import "/node_modules/bootstrap/scss/navbar";
//@import "/node_modules/bootstrap/scss/card";
//@import "/node_modules/bootstrap/scss/breadcrumb";
//@import "/node_modules/bootstrap/scss/pagination";
//@import "/node_modules/bootstrap/scss/badge";
//@import "/node_modules/bootstrap/scss/jumbotron";
//@import "/node_modules/bootstrap/scss/alert";
//@import "/node_modules/bootstrap/scss/progress";
//@import "/node_modules/bootstrap/scss/media";
//@import "/node_modules/bootstrap/scss/list-group";
//@import "/node_modules/bootstrap/scss/close";
//@import "/node_modules/bootstrap/scss/toasts";
//@import "/node_modules/bootstrap/scss/modal";
//@import "/node_modules/bootstrap/scss/tooltip";
//@import "/node_modules/bootstrap/scss/popover";
//@import "/node_modules/bootstrap/scss/carousel";
//@import "/node_modules/bootstrap/scss/spinners";
//@import "/node_modules/bootstrap/scss/utilities";
//@import "/node_modules/bootstrap/scss/print";








@import "./reset";
@import "./variables";
@import "./user-selects";





html {
    font-size: 14px;
    background: $black;
    overflow-y: scroll;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    @include type-sm;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include type-sm;
}

.modal-open {
    overflow: hidden;
    body{
        padding-right: 15px;
    }
}

* {
    box-sizing: border-box;
}


h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}


a{
    color: inherit;
    text-decoration: none;
}
