@import "../../styles/variables";

.button {
  @include type-display-sm;

  align-items: center;
  background: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
  width: 100%;
  color: $black;
  padding: 0;
  height: 4rem;
}