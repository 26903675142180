/*
$black: #002786;
$grey: #29b727;
$orange: #b281f6;
$violet: #145b28;
$white: #e6feff;
*/


$black: #222222;
$lightgrey: #f3f3f3;
$grey: #E6E6E6;
$orange: #FF671B;
$violet: #753DBC;
$white: #FFFFFF;