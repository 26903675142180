@import "../../styles/variables";

.wrapper {
  padding: .5rem;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.badge {
  width: 2rem;
  height: 2rem;
  position: relative;
  background: $orange;
  border-radius: 1.5rem;
  color: $white;
  @include type-sm;
  letter-spacing: 0;
  font-size: 12px;
  line-height: 2rem;
  text-align: center;
}

.active.badge {
  background: $white;
  color: $orange;
}

