@import "../../styles/variables";


.footer {
  width: 100%;
  height: auto;
  overflow-y: hidden;

  background: $black;
  color: $white;


  a {
    text-decoration: none;
    @include type-display-sm;
    @include tablet-down{
    }
  }

}

h5.sender{
  padding: 2rem;
  margin: 0;
  @include type-display-sm;
  @include tablet-down{
  }
}


