.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}



.fade-700-enter {
    opacity: 0;
}
.fade-700-enter-active {
    opacity: 1;
    transition: opacity 700ms;
}
.fade-700-exit {
    opacity: 1;
}
.fade-700-exit-active {
    opacity: 0;
    transition: opacity 700ms;
}