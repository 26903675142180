@import "../../styles/variables";

.wrapper {
}

.row {
  position: relative;
}


.headerTrack {
  border-top: 1px solid $white;
  background: $violet;
  color: $white;
  display: flex;
  width: 100%;
  @include desktop {
    display: none;
  }
  @include maxi {
    display: none;
  }
}

.headerSlide {
  padding: .5rem;
  position: sticky;
  left: 0;
  width: auto;
}