.stage {
  width: 100%;
  overflow-x: auto;
  user-select: none;
}

.track {
  width: 200%;
  position: relative;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}