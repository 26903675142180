@import "../../styles/variables.scss";

.button {
  background: none;
  height: auto;
  width: auto;

  margin: 0;
  padding: 0;

  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0;

}