@import "../../styles/variables";


.linkSection{
  margin-bottom: 1rem;
}
.linkRow{
  display: flex;
}
.socialLink{
  display: block;
}


.embeds{

}
.embed{
  width: 100%;
  margin-bottom: 2rem;

}
.ratio{
  width: 100%;
  padding-top: 75%;
  position: relative;
  iframe{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}