@import "../../../styles/variables";


.hidemobile {
  display: none !important;
}


.hidedesktop {
  @include desktop {
    display: none !important;
  }
}
