@import "../../styles/variables";

.wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: $white;
  display: flex;
  border-bottom: 1px solid $white;
}


.logoWrapper {
  width: 100%;
  flex: 1;
  position: relative;
  @include mobile{
    display: none;
  }
}
.logo{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

}




.linkWrapper {
  display: block;
  flex: 1;
}


.linkWrapper+.linkWrapper{
  border-left: 1px solid $white;
}


.favLinkWrapper {
  width: 100%;
  flex: 1;
  border-left: 1px solid $white;
  @include mobile {
    flex: 0 0 4rem;
  }
}

.favLink {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
