@import "../../styles/variables";

.container {
  overflow: hidden;
  position: relative;
  height: 1.5rem;

}

.stage{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  @include type-sm;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  background: $violet;
  color: $white;
  display: flex;
}

.hour {
  position: relative;
}
.firstHour{
  position: absolute;
  height: 100%;
  background: blue;
}

.label {
  position: absolute;
  left: 0;
  font-size: 11px;
  // transform: translateX(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4px;
}


.ruler {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}
.rulerBottom{
  align-items: flex-end;
}
.unit {
  height: 100%;
  width: 25%;
}

.full {
  border-left: 2px solid $white;
  height: 100%;
}

.half {
  border-left: 1px solid $white;
  height: 50%;
}

.quarter {
  border-left: 1px solid $white;
  height: 25%;
}