/*
    Neue Haas Grotesk Display Pro 45 Light
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;

    Neue Haas Grotesk Display Pro 46 Light Italic
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: italic;

    Neue Haas Grotesk Display Pro 75 Bold
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;

    Neue Haas Grotesk Display Pro 76 Bold Italic
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: italic;

    Neue Haas Grotesk Text Pro 55 Roman
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: normal;

    Neue Haas Grotesk Text Pro 56 Italic
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: italic;

    Neue Haas Grotesk Text Pro 75 Bold
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;

    Neue Haas Grotesk Text Pro 76 Bold Italic
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: italic;
*/

@mixin type-display-lg {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.56px;
    line-height: 1.1;
}
.type-display-lg{
    @include type-display-lg;
}

@mixin type-display-sm {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    letter-spacing: 0.44px;
    line-height: 1.3rem;
    text-transform: uppercase;
}
.type-display-sm{
    @include type-display-sm;
}

@mixin type-display-xs {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 1.3rem;
    text-transform: uppercase;
}
.type-display-xs{
    @include type-display-xs;
}

@mixin type-lg {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    line-height: 1.1;
}
.type-lg{
    @include type-lg;
}

@mixin type-sm {
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    letter-spacing: 0.02em;
    line-height: 1.3rem;
}
.type-sm{
    @include type-sm;
}