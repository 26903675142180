@import "../../styles/variables";


.container{
  border-top: 1px solid $black;
}


.button{
  @include button-block;
  @include type-display-xs;

  min-height: 4rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $black;
  color: $black;
}
.activeButton{
  background: $black;
  color: $white;
}

.reset{


}


.resetBtn{
  @include button-block;
  @include type-display-xs;
  color: $black;
  min-height: 4rem;
  display: flex;
  align-items: center;
}