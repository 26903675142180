@import "../../styles/variables";

.nav{
  width: 100%;
  display: flex;
  border-top: 1px solid $white;
}

.linkWrapper{
  flex-basis: 50%;
  a{
    padding: 1rem;
    display: block;
    text-align: center;
  }
}

.linkWrapper+.linkWrapper{
  border-left: 1px solid $white;
}