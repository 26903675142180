@import "../../styles/variables.scss";


.link {
  @include type-display-sm;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 4rem;
  color: $white;
  background: $black;
  border-top: 2px solid $black;
  transition: background-color 300ms linear, color 300ms linear;
}


.link:hover {
  border-color: $orange;
}


.active {
  background: $violet !important;
  color: white !important;
  border-top: 2px solid $orange;
}



.favlink {
  @include type-display-sm;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  height: 4rem;
  color: $white;
  background: $black;
  border-top: 2px solid $black;
  transition: background-color 300ms linear, color 300ms linear;
}


.favlink:hover {
  border-color: $orange;
}


.active {
  background: $violet !important;
  color: white !important;
  border-top: 2px solid $orange;
}





