@import "../../styles/variables";

.wrapper {
  display: flex;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-bottom: 3rem;
}

@include tablet {
  .wrapper {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@include desktop {
  .wrapper {
    padding-left: 20%;
    padding-right: 40%;
  }
}


.backdrop {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
}

.inner {
  position: relative;
  z-index: 2;
  background: $white;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow: auto;
  padding-bottom: 2.5rem;
  box-shadow: 0 35px 55px 0 rgba(0, 0, 0, 0.22);
}


.header {
  @include type-sm;
  background: #753DBC;
  padding: 1.5rem 2rem;
  color:  $white;
}

.block {
  @include type-lg;
  margin: 0 2rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid #753DBC;
}