@import "../../styles/variables";



.stage {
  border-bottom: 1px solid $white;
  border-left: 4px solid $orange;
  border-right: 1px solid $white;

  height: 100%;
  background: $black;
  color: $white;
  overflow: hidden;

  display: flex !important;
}

.fav.stage {
  background: $orange;
  border-left-color: $black;
}

.stage:hover, .fav.stage:hover{
  background-color: $violet;
}

.track{
  position: relative;
  max-width: 100%;
  transition: transform 50ms ease-out;
}