@import "../../styles/variables";


.back {
  border-bottom: 1px solid $black;
}

.container {
  @include tablet-up {
    max-width: 60%;
    margin: 0 auto;
  }
  @include tablet{
    max-width: 80%;

  }
}

.header {
  button{
    margin: -1rem;
  }
  user-select: text;
}

.body {
}

.block {
  @include tablet-down{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  margin: 3rem 0;
}

.events{
  max-width: 40em;
}

.bodyWrapper {
  max-width: 40em;
}

.image {
  max-width: 40em;
}