@import "../../styles/variables";

.container {
  @include type-sm;
  padding: 1rem;
  color: $white;
  background: $black;
  border-left: 4px solid $orange;
}

.fav.container {
  border-left: 4px solid $black;
  background: $orange;
  a:hover {
    color: $black;
  }
}

.block {
  @include type-sm;
}

.block h5 {
  @include type-lg;
}

.block a {
  text-decoration: none;

  &:hover {
    color: $orange;
  }
}

.block + .block {
  margin-top: .75rem;
}
.titleBlock{
  h5{
    display: flex;
  }
  svg {
    display: inline;
    height: 1em;
    width: 1em;
    margin-right: .2em;
    vertical-align: middle;
    margin-right: .5rem;
    * {
      fill: currentColor;
    }
  }
}

.date {
  @include type-display-xs;
  line-height: 1.1
}


.locatorIcon {
}

.locatorIcon svg {
  display: inline;
  height: 1.3em;
  width: 1.3em;
  margin-right: .5rem;
  vertical-align: bottom;

  * {
    fill: currentColor;
  }
}