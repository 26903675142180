@import "../../styles/variables.scss";

.container{
  padding: 2rem 2rem 2rem 0;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include tablet-down{
    padding: 2rem;
    padding-right: 1rem;
  }
  @include tablet-up{
    min-height: 8rem;
  }
}