@import "../../styles/variables";




.container{
  display: flex;
  text-decoration: none !important;
  border-top: 1px solid $violet;
  align-items: center;
}
.container.active{
  background: $orange;
}



.iconWrapper{
  padding: .5rem;
}
.textWrapper{
  padding: 1rem;
  padding-left: .25rem;


  @include desktop{
    @include type-sm;
  }

  @include type-lg;

}


.active {
  &.container {
  }
  .textWrapper{
  }
}
