@import "../../styles/variables";

.form{
    @include type-display-lg;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 4rem;
    overflow: hidden;
}

.input {
    @include type-display-lg;
    display: block;
    width: 100%;
    max-width: 600px;
    height: 4rem;
    padding: 0;
    margin-right: 1rem;
    background: $grey;
    border-radius: 0;
    @include tablet-up{
        margin-right: 3rem;
    }
    border: none;
    outline: none;
}