@import "../../styles/variables";

.wrapper {
  padding-top: 100%;
  width: 100%;
  position: relative;
  background: $grey;
}

.thumbnail {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}